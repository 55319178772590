.app-footer {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background: #202124;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    z-index: 100;
    box-shadow: 0 -1px 4px 0 rgba(0,0,0,0.2);
}

.footer-logo {
    max-height: 40px;
    width: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
}

.social-icons {
    display: flex;
    align-items: center;
    margin-left: 18px;
    z-index: 1;
}

.icon {
    fill: white;
    width: 24px;
    height: 24px;
    margin-right: 30px;
    cursor: pointer;
}

.icon:last-child {
    margin-right: 28;
}

.icon:hover {
    fill: #aaa;
}

.copyright {
    font-size: 12px;
    text-align: right;
    color: #ffffff3b;
    margin-left: 30px;
    z-index: 1;
    margin-right: 12px;
}

@media (max-width: 768px) {
    .app-footer {
        flex-direction: row;
        justify-content: space-between;
    }

    .footer-logo {
        position: static;
        margin-left: auto;
        margin-right: 20px;
        order: 2; /* Moves the logo to the right */
    }

    .social-icons {
        order: 1; /* Keeps the social icons on the left */
        margin-right: auto;
    }

    .icon {
        width: 20px;
        height: 20px;
    }

    .copyright {
        display: none;
    }
}
