.verification-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Aligns content towards the top */
    align-items: center;
    height: 100vh;
    background-color: #202124;
    padding-top: 20%; /* Adds some margin at the top */
}

.verifying-text {
    color: white;
    font-family: 'Arial', sans-serif; /* Update with your desired font family */
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
}

.loader {
    border: 5px solid #556cc9;
    border-top: 5px solid #feb845; /* Adjust color to match your brand */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.success-message {
    color: green;
    font-size: 24px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.checkmark {
    font-size: 30px;
}

.email-verify-button {

    padding-left: 28px;
    padding-right: 28px;
    font-size: 20px;
    cursor: pointer;
    width: auto; /* Allow the button to size to its content */
    margin-top: 71px;
}

.email-success-message {
    align-items: center;
    color: green;
    display: flex;
    font-size: 22px;
    gap: 14px;
    padding-right: 18px;
}