.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 50px;
}

.qr-early-access-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    justify-content: center;
    align-items: center;
}

.qr-early-access-modal.is-open {
    display: flex;
}

.qr-early-access-modal h2 {
    margin-bottom: 20px;
    color: rgb(254 184 69);
}

.qr-early-access-modal p {
    margin-bottom: 20px;
}

.qr-early-access-modal hr {
    margin-bottom: 20px;
}

.qr-early-access-modal button {
    background-color: #ffffff12;
    color: white;
    cursor: pointer;
    border: none;
    border: solid;
    border-color: #feb74526;
    border-width: thin;
    margin-top: 36px;
}

.qr-early-access-modal button:hover {
    background-color: #feb7459f;
}

.qr-early-access-modal img {
    margin-right: 10px;
    width: 200px;
    height: auto;
}
.qr-code-container {
    margin-top: 40px; /* Adjust this value as needed */
}

.modal-content {
    background-color: #292b2c;
    padding: 20px;
    border-radius: 5px;
    color: white;
    width: 500px;
    z-index: 10000;
    margin-right: 15px;
    margin-left: 15px;
}