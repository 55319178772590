.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: flex-start;  /* Changed to flex-start to move the modal to the top */
    padding-top: 50px;  /* Added padding to push modal down a bit from the top */
}

.wallet-connect-modal {
    background-color: #292b2c;
    padding: 20px;
    border-radius: 5px;
    color: white;
    max-width: 500px;
    z-index: 10000;
    margin-top: 130px;
}

.wallet-connect-modal h2 {
    margin-bottom: 20px;
    color: rgb(254 184 69);
}

.wallet-connect-modal p {
    margin-bottom: 20px;
}

.wallet-connect-modal hr {
    margin-bottom: 20px;
}

.wallet-connect-modal button {
    display: flex;  /* Changed to flex to align items */
    align-items: center;  /* Align items vertically */
    width: 100%;  /* Make it full width */
    padding: 15px;  /* Increased padding */
    font-size: 1em;
    background-color: #292b2c;
    color: #ffc107;
    border: none;
    border-radius: 5px;  /* Reduced border-radius */
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-align: left;  /* Align text to the left */
}

.wallet-connect-modal button:hover {
    background-color: #ffc107;  
    color: #292b2c;
}

.wallet-connect-modal button img {  /* Style for the SVG icon */
    margin-right: 10px;  /* Add some space between the icon and text */
}

.wallet-icon {
    width: 214px; /* Adjust the size as needed */
    height: auto;
    padding-left: 20px;
}

.wallet-option {
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    display: inline-block;
    transition: background-color 0.3s ease;
    width: 100%;
}

.wallet-option:hover {
    background-color: rgba(255, 255, 255, 0.1);
}