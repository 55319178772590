.create-nft-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 20px;
    max-width: 1200px;
}

.nft-form-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.nft-left-section {
    flex-basis: 44%;
    flex-direction: column;
    align-items: flex-start;
}

.nft-right-section {
    flex-basis: 40%;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 20px;
    padding-top: 54px;
}

.nft-label, .nft-label-text, .nft-input {
    width: calc(100% - 20px);
}

.nft-label {
    margin-top: 20px;
}

.nft-label-text {
    display: block;
    margin-bottom: 5px;
    color: #999;
    text-align: left;
}

.nft-input {
    border: 1px solid #ccc;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    border-radius: 5px;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 25px;
}

.nft-textarea {
    height: 100px;
    resize: none;
}

.nft-dropzone {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #ccc;
    border-radius: 5px;
    color: #ccc;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;  /* Added this line */
}

.nft-dropzone:hover {
    background-color: rgba(254, 184, 69, 0.5);
    color: white;
    border-color: transparent;
}

.nft-styled-button {
    padding: 10px 20px;
    font-size: 18px;
    border: 1px solid rgba(253, 253, 253, 0.295);
    color: white;
    background-color: transparent;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin: 60px auto;
    display: block;
    width: 500px;
}

.nft-styled-button:hover {
    background-color: rgba(254, 184, 69, 0.5);
    color: #202124;
}

@media (max-width: 768px) {
    .nft-form-container {
        flex-direction: column;
        align-items: center;
    }

    .nft-left-section, .nft-right-section {
        max-width: 100%;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .nft-right-section {
        margin-top: 20px;
    }

    .nft-styled-button {
        max-width: 500px;
        width: 100%;
    }
}
