.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  background-color: #202124;
  color: white;
  z-index: 1000;
  position: sticky;
  top: 0;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}

.left-section,
.right-section {
  display: flex;
  align-items: center;
}

.logo {
  position: relative;
  margin-right: 15px;
}

.logo img {
  width: 120px;
  height: auto;
  vertical-align: middle;
}

.logo::after {
  content: "";
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  height: 150%;
  width: 1px;
  background-color: white;
  margin-left: 15px;
}

nav {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 15px;
}

.nav-link {
  color: white;
  text-decoration: none;
  margin: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between; /* Updated to space the items */
}

.nav-link:hover {
  color: rgba(254, 184, 69, 1);
}

.icons .icon,
.wallet-info .icon {
  font-size: 18px;
  margin-left: 24px;
  cursor: pointer;
  vertical-align: middle;
  transition: color 0.3s ease-in-out;
}

.icons .icon:hover,
.wallet-info .icon:hover {
  color: rgba(254, 184, 69, 1);
}

.wallet-info {
  display: flex;
  align-items: center;
}

.wallet-info span {
  margin-left: 12px;
  vertical-align: middle;
}

.mobile-menu {
  position: fixed;
  top: 60px;
  right: -100%;
  width: 100%;
  height: calc(100vh - 60px);
  background: rgba(0, 0, 0, 0.9);
  color: white;
  z-index: 999;
  transition: right 0.3s ease-in-out;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Updated alignment */
}

.mobile-menu.open {
  right: 0;
}

.mobile-menu .nav-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0; /* Adjusted margin */
}

.menu-icon {
  margin-right: 10px; /* Added margin */
}

.close-icon {
  cursor: pointer;
  color: white;
  transition: color 0.3s ease-in-out;
  margin-left: auto; /* Push the icon to the right */
}

.close-icon:hover {
  color: rgba(254, 184, 69, 1);
}
