.coming-soon-container {
    margin: auto;
    margin-top: 48px;
    font-family: monospace;
    color: #ffffff; /* White text color for the content */
}

/* Container for the header and form to align their widths */
.heading-form-container {
    display: inline-block;
    text-align: left; /* Align text left for the label and form */
    margin-bottom: 20px; /* Space between header and form */
}

.heading-form-container p {
    font-size: large;
}

h1 {
    text-align: center; /* Center text for the header */
    margin-bottom: 0; /* Remove margin below h1 to align with form */
}

/* Styles for the subscription form */
form.subscription-form {
    margin-top: 90px;
}

/* Label style */
.email-label {
    display: block;
    margin-bottom: 12px;
    text-align: left; /* Align label text to the left */
    font-size: large;
}


/* Unified input and button styles */
input[type="text"], input[type="password"], input[type="email"], button {
    padding: 10px;
    margin-bottom: 20px; /* Space below inputs and button */
    font-size: 1em;
    border: none; /* Remove all borders */
    border-radius: 0; /* No rounded corners */
    background-color: #292b2c; /* Different background color for the input */
    width: 100%; /* Force full width to match the header */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

/* Specific styles for email input */
input[type="email"] {
    color: white;
    border-bottom: 1px solid white; /* Only a bottom border */
}

/* Placeholder color for inputs */
input::placeholder {
    color: rgba(255, 255, 255, 0.7);
    font-style: italic; 
}

/* Button styles */
button {
    background-color: #ffffff12;
    color: white;
    cursor: pointer;
    border: none;
    border: solid;
    border-color: #feb74526;
    border-width: thin; 
}

/* Button hover effect */
button:hover {
    background-color: #feb7459f;
}

/* New container for the tile section */
.tile-container {
    max-width: 400px;
    min-height: 200px;
    background-color: transparent;
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0px 0px 11px 0 rgba(0,0,0,0.15);
    display: table;
    width: 70%;
    margin-top: 60px;
    border: solid;
    border-color: rgb(102 102 102 / -4%);
}

/* Styles for the early access tile within the container */
.early-access-tile {
    text-align: center; /* Center the contents */
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Logo styles */
.arco-wallet-logo {
    max-width: 200px;
    margin-bottom: 20px;
}

/* Early access button styles */
.early-access-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    width: auto; /* Allow the button to size to its content */
    margin-top: 23px;
}

/* Additional messaging styles */
.wallet-message {
    margin-top: 20px;
    color: red; /* Adjust based on wallet detection */
}

/* Subtext styles */
.subtext {
    margin: 10px 0;
    font-size: 18px;
    margin-top: 20px;
}

.email-input.valid {
    border-color: green; /* Change color as needed */
}

button:disabled {
    background-color: #ffffff12; /* Grey background for disabled button */
    color: #777; /* Grey text for disabled button */
    cursor: not-allowed; /* Cursor indicates button is not clickable */
}